var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brandStorySubmission" },
    [
      _c("LincolnEffortlessExperienceLogoDark", { staticClass: "logo" }),
      _c("div", { staticClass: "form-title" }, [
        _vm._v("Submit Your Ideas - Submission Form"),
      ]),
      _c("p", [
        _c("span", { staticClass: "directions" }, [_vm._v("Directions:")]),
        _vm._v(
          '\n    Please complete all of the fields below and add any relevant file attachments\n    that support your idea, including photos. When ready, click "Send Now" and your\n    submission will be forwarded for review. You may be contacted if any additional\n    information or clarification is needed. Should your submission be published,\n    you will find it in the "TOP POSTS BY" section of your profile. If you need\n    assistance completing this form, you may contact Program Headquarters at\n    '
        ),
        _c("a", { attrs: { href: _vm.supportEmailHref } }, [
          _vm._v(_vm._s(_vm.supportEmail)),
        ]),
        _vm._v(".\n  "),
      ]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "First Name",
              name: "firstName",
              placeholder: "First Name",
              disabled: true,
            },
            model: {
              value: _vm.user.nameFirst,
              callback: function ($$v) {
                _vm.$set(_vm.user, "nameFirst", $$v)
              },
              expression: "user.nameFirst",
            },
          }),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Last Name",
              name: "lastName",
              placeholder: "Last Name",
              disabled: true,
            },
            model: {
              value: _vm.user.nameLast,
              callback: function ($$v) {
                _vm.$set(_vm.user, "nameLast", $$v)
              },
              expression: "user.nameLast",
            },
          }),
          _c("TextBox", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.user.dealership,
                expression: "user.dealership",
              },
            ],
            staticClass: "textBox",
            attrs: {
              label: "Dealership",
              name: "dealership",
              placeholder: "Dealership",
              disabled: true,
            },
            model: {
              value: _vm.user.dealership,
              callback: function ($$v) {
                _vm.$set(_vm.user, "dealership", $$v)
              },
              expression: "user.dealership",
            },
          }),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Subject",
              name: "subject",
              required: true,
              placeholder: "Subject *",
            },
            model: {
              value: _vm.brandStory.subject,
              callback: function ($$v) {
                _vm.$set(_vm.brandStory, "subject", $$v)
              },
              expression: "brandStory.subject",
            },
          }),
          _c("div", { staticClass: "required" }, [
            _vm._v("Fields marked with * are required."),
          ]),
          _vm._l(this.businessAreas, function (area) {
            return _c("BusinessAreaOption", {
              key: area.id,
              attrs: {
                label: area.name,
                name: _vm.businessAreasName,
                value: area.id,
              },
              on: { updateSelectedAreas: _vm.updateSelectedAreas },
            })
          }),
          _c("div", { staticClass: "required" }, [
            _vm._v("Please select one."),
          ]),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "tenetContainer" },
            _vm._l(this.tenets, function (tenet) {
              return _c("EffortlessTenetOption", {
                key: tenet.id,
                attrs: {
                  label: tenet.name,
                  name: _vm.effortlessTenetsName,
                  value: tenet.id,
                },
                on: { updateSelectedTenets: _vm.updateSelectedTenets },
              })
            }),
            1
          ),
          _c("TextBox", {
            staticClass: "textBox details",
            attrs: {
              name: "body",
              required: true,
              type: "textarea",
              placeholder: _vm.bodyText,
            },
            model: {
              value: _vm.brandStory.body,
              callback: function ($$v) {
                _vm.$set(_vm.brandStory, "body", $$v)
              },
              expression: "brandStory.body",
            },
          }),
          _vm.attachments.length == 0
            ? _c("div", { staticClass: "required" }, [
                _vm._v(
                  "\n      Include attachments to support your idea, such as photos, documents, or videos.\n      Earn 10 extra points for your original photos!\n    "
                ),
              ])
            : _vm._e(),
          _c("MultipleFiles", {
            attrs: {
              name: _vm.attachments,
              copy: "Upload Photos&nbsp;/&nbsp;Files&nbsp;/&nbsp;Videos",
            },
            model: {
              value: _vm.attachments,
              callback: function ($$v) {
                _vm.attachments = $$v
              },
              expression: "attachments",
            },
          }),
          _c("ButtonSubmit", {
            attrs: { copy: "Send Now" },
            on: { callback: _vm.submit },
          }),
          _c("ButtonCancel", {
            staticClass: "cancelButton",
            on: { click: _vm.closeModal },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tenet" }, [
      _vm._v("\n      Effortless Tenet\n      "),
      _c("div", { staticClass: "arrowDown" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }